<template>
  <div class="results">
    <div class="input">
      <div class="searchField">
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.9459 16.7062L12.1027 10.863C13.0094 9.69071 13.4999 8.25746 13.4999 6.74997C13.4999 4.94548 12.7957 3.25349 11.5222 1.97774C10.2487 0.701997 8.55221 0 6.74997 0C4.94773 0 3.25124 0.704247 1.97774 1.97774C0.701997 3.25124 0 4.94548 0 6.74997C0 8.55221 0.704247 10.2487 1.97774 11.5222C3.25124 12.7979 4.94548 13.4999 6.74997 13.4999C8.25746 13.4999 9.68846 13.0094 10.8607 12.1049L16.7039 17.9459C16.7211 17.9631 16.7414 17.9767 16.7638 17.9859C16.7862 17.9952 16.8102 18 16.8344 18C16.8587 18 16.8827 17.9952 16.9051 17.9859C16.9274 17.9767 16.9478 17.9631 16.9649 17.9459L17.9459 16.9672C17.9631 16.95 17.9767 16.9297 17.9859 16.9073C17.9952 16.8849 18 16.8609 18 16.8367C18 16.8124 17.9952 16.7884 17.9859 16.766C17.9767 16.7437 17.9631 16.7233 17.9459 16.7062ZM10.314 10.314C9.35996 11.2657 8.09546 11.7899 6.74997 11.7899C5.40448 11.7899 4.13998 11.2657 3.18599 10.314C2.23424 9.35996 1.70999 8.09546 1.70999 6.74997C1.70999 5.40448 2.23424 4.13773 3.18599 3.18599C4.13998 2.23424 5.40448 1.70999 6.74997 1.70999C8.09546 1.70999 9.36221 2.23199 10.314 3.18599C11.2657 4.13998 11.7899 5.40448 11.7899 6.74997C11.7899 8.09546 11.2657 9.36221 10.314 10.314Z"
            fill="white"
          />
        </svg>
        <input type="text" v-model="this.input" @keypress.enter="inputSearch" />
      </div>
      <div class="filters">
        <div class="filterScroll">
          <div class="filter">
            <select name="type" v-model="this.type" @change="optionChange">
              <option v-for="type in types" :key="type[0]" :value="type[1]">
                {{ type[0] }}
              </option>
            </select>
          </div>
          <div class="filter">
            <select name="order" v-model="this.order" @change="optionChange">
              <option v-for="order in orders" :key="order[0]" :value="order[1]">
                {{ order[0] }}
              </option>
            </select>
          </div>
          <div class="filter">
            <select name="date" v-model="this.date" @change="optionChange">
              <option v-for="date in dates" :key="date[0]" :value="date[1]">
                {{ date[0] }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="section courses" v-if="this.courses.length">
      <h2>Courses</h2>
      <div class="scroll">
        <ul>
          <li v-for="course of this.courses" :key="course.name" :style="`background-color: ${this.colors[course.group][1]}; `">
            <router-link :to="`/courses/${course.name}`">
              <h3>{{ course.name }}</h3>
              <h4>{{ course.group }}</h4>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="section media" v-if="this.media.length">
      <h2>Media</h2>
      <div class="scroll">
        <ul>
          <li v-for="medium of this.media" :key="medium.title">
            <router-link :to="`/topics/${medium.title}?scrollTo=${medium._id}`">
              <div class="mediaOrigin origin">
                <img :src="`${medium.thumbnail}?token=${this.token}`" :alt="`${medium.title} thumbnail`" @error="replaceSource" />
                <div class="mediaInfo">
                  <h3>{{ medium.title }}</h3>
                  <h4>{{ medium.group }}</h4>
                </div>
              </div>
              <div class="mediaContent">
                <img :src="`${medium.source}?token=${this.token}`" :alt="`${medium.alt}`" @error="replaceSource" />
                <p>{{ medium.alt }}</p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="section text" v-if="this.text.length">
      <h2>In-Text</h2>
      <ul>
        <li v-for="text of this.text" :key="text.title">
          <router-link :to="`/topics/${text.title}?scrollTo=${text.text?._id}${text.text.index !== undefined ? `&index=${text.text.index}` : ''}`">
            <div class="textOrigin origin">
              <img :src="`${text.thumbnail}?token=${this.token}`" :alt="`${text.title} thumbnail`" @error="replaceSource" />
              <div class="textInfo">
                <h3>{{ text.title }}</h3>
                <h4>{{ text.group }}</h4>
              </div>
            </div>
          </router-link>
          <p class="textContent">{{ text.text.text }}</p>
        </li>
      </ul>
    </div>
    <div class="section topics" v-if="this.topics.length">
      <h2>Topics</h2>
      <div class="scroll">
        <ul>
          <li v-for="topic of this.topics" :key="topic.title">
            <router-link :to="`/topics/${topic.title}`">
              <img :src="`${topic.thumbnail}?token=${this.token}`" :alt="`${topic.title} thumbnail`" @error="replaceSource" />
              <div class="text">
                <h3>{{ topic.title }}</h3>
                <h4>{{ topic.group }}</h4>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topics: [],
      courses: [],
      text: [],
      media: [],

      input: "",

      type: undefined,
      date: undefined,
      order: undefined,
      dates: [
        ["Date", undefined],
        ["Today", 1],
        ["This Week", 7],
        ["This Month", 30],
      ],
      orders: [
        ["Order", undefined],
        ["Alphabetical", "alphabetical"],
        ["Rev. Alpha.", "reversealph"],
        ["From Newest", "fromnewest"],
        ["From Oldest", "fromoldest"],
      ],
      types: [
        ["Type", undefined],
        ["Course", "course"],
        ["Topic", "topic"],
        ["Text", "text"],
        ["Media", "media"],
      ],
    };
  },
  props: {
    token: String,
    colors: Object,
  },
  methods: {
    async search(query = this.$route.query.query) {
      if (!query) return;

      this.results = [];

      const req = await fetch(`/api/search/?query=${query}${this.type ? `&type=${this.type}` : ""}${this.order ? `&order=${this.order}` : ""}${this.date ? `&date=${this.date}` : ""}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });

      // this.$emit("loaded");

      if (req.status === 200) {
        const res = await req.json();

        this.topics = [];
        this.courses = [];
        this.text = [];
        this.media = [];

        for (let i = 0; i < res.results.length; i++) {
          if (res.results[i].type === "topic") {
            this.topics.push(res.results[i]);
          }
          if (res.results[i].type === "course") {
            this.courses.push(res.results[i]);
          }
          if (res.results[i].type === "text") {
            this.text.push(res.results[i]);
          }
          if (res.results[i].type === "image") {
            this.media.push(res.results[i]);
          }
          if (res.results[i].type === "list") {
            this.text.push({
              title: res.results[i].title,
              group: res.results[i].group,
              type: "text",
              thumbnail: res.results[i].thumbnail,
              text: {
                text: res.results[i].list.list[res.results[i].index],
                index: res.results[i].index,
                _id: res.results[i].list._id,
              },
            });
          }
        }
      } else if (req.status === 429) {
        return this.$emit("displayMessage", [false, "Please wait to search again."]);
      } else {
        const res = await req.json();
        return this.$emit("displayMessage", [false, res.message]);
      }
    },
    inputSearch() {
      this.$router.push(`/search?query=${this.input}`);
    },
    optionChange() {
      this.search();
    },
    replaceSource(e) {
      e.target.src = "/images/notFoundImage.png";
    },
  },
  watch: {
    "$route.query.query": function () {
      this.search(this.$route.query?.query);
      this.input = this.$route.query?.query;
      // if (!this.input) this.$emit("loaded");
    },
  },
  created() {
    this.$emit("changeColor", "search");
    this.search(this.$route.query?.query);
    this.input = this.$route.query?.query;
    // if (!this.input) this.$emit("loaded");
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: #e5e5e5;
}

::-webkit-scrollbar-thumb {
  background: v-bind("colors.search[0]");
}

::-webkit-scrollbar-thumb:hover {
  background: v-bind("colors.search[1]");
}

.results {
  padding-bottom: 200px;
}
.input {
  padding: 60px 20px 0 20px;
  width: 100%;
  height: 220px;
}
.input .searchField {
  max-width: 900px;
  margin: 20px auto 15px auto;

  display: flex;
  gap: 10px;

  padding: 5px 5px 5px 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 15px 10px rgba(100, 100, 100, 0.15);
}
.input svg {
  width: 22px;
  aspect-ratio: 1/1;
}
.input path {
  fill: rgb(105, 105, 105);
}
.input input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 10px;
  font-size: 26px;
  color: rgb(89, 89, 89);
  outline: none;
  border-radius: 8px;
  border: 1px solid transparent;
}

.filters {
  max-width: 920px;
  margin: 0 auto;
  position: relative;
}
.filters::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
  animation: animateColorsRight 0.4s forwards;
}
.filters::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  animation: animateColorsLeft 0.4s forwards;
}
.filterScroll {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 8px 10px;

  display: flex;
  gap: 15px;
}
.filter {
  background-color: white;
  padding: 4px 10px 4px 10px;
  border-radius: 20px;
  box-shadow: 0 0 15px 5px rgba(131, 57, 192, 0.15);
}
.filter select {
  border: none;
  outline: none;
  font-size: 16px;
}

.section {
  width: 100%;
}
.section h2 {
  max-width: 940px;
  margin: 0 auto;
  padding: 20px 20px 0 20px;

  font-size: 30px;
  font-weight: 700;
  color: rgb(61, 61, 61);

  margin-bottom: 10px;
}
.fade {
  margin-left: -15px;
  width: calc(100% + 30px);
}

.scroll {
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
}
.section ul {
  display: flex;
  width: fit-content;

  padding: 10px 0 20px 0;
  padding-left: calc((100% - 940px) / 2 + 20px);
  padding-right: calc((100% - 940px) / 2 + 20px);

  gap: 20px;
  list-style: none;
}
.section li {
  height: fit-content;
}
.section a {
  text-decoration: none;
}
/* Try grid again sometime
.topics ul {
}
.courses ul {
} */
.courses li {
  padding: 6px 32px 6px 12px;
  border-radius: 4px;
}
.courses li h3 {
  color: white;
  font-weight: 500;
  font-size: 20px;
  white-space: nowrap;
}
.courses li h4 {
  color: white;
  font-weight: 400;
  font-size: 14px;
}
.topics li {
  aspect-ratio: 1/1;
  height: 300px;
  border-radius: 4px;
  background-color: rgba(138, 138, 138, 0.15);
  box-shadow: 0 5px 15px 5px rgba(138, 138, 138, 0.1);
  position: relative;
  overflow: hidden;
}
.topics li .text {
  border-top: 1px dotted rgb(200, 200, 200);
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 10px 10px 14px;
  transition: 0.3s;
}
.topics li:hover .text {
  padding-bottom: 34px;
}
.topics li img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.topics h3 {
  font-size: 20px;
  font-weight: 500;
  color: rgb(43, 43, 43);
}
.topics h4 {
  font-size: 16px;
  font-weight: 400;
  color: rgb(73, 73, 73);
}

.origin {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 15px;
  background-color: white;
  padding: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.origin h3 {
  color: rgb(52, 52, 52);
  font-weight: 400;
  font-size: 14px;
}
.origin h4 {
  color: rgb(52, 52, 52);
  font-weight: 400;
  font-size: 10px;
}
.origin img {
  width: 34px;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-color: rgba(138, 138, 138, 0.1);
  box-shadow: 0 4px 12px 4px rgba(71, 71, 71, 0.1);
  border-radius: 4px;
}

.text ul {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  padding: 0 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}
.text li {
  border-radius: 4px;
  /* background-color: rgb(235, 235, 235); */
  /* box-shadow: 0 5px 15px 5px rgba(138, 138, 138, 0.1); */
  /* overflow: hidden; */
}
.textOrigin {
  width: fit-content;
  padding-right: 40px;
  padding-left: 20px;
  background-color: transparent;
  box-shadow: none;
}
.textContent {
  text-align: left;
  padding: 20px;
  color: rgb(27, 27, 27);
  font-weight: 500;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 5px 20px 5px rgba(138, 138, 138, 0.1);
}

.media li {
  box-shadow: 0 5px 15px 5px rgba(138, 138, 138, 0.1);
}
.media li .mediaContent {
  display: grid;
  grid-template-rows: 150px auto;
  background-color: white;

  border-top: 1px dotted rgb(215, 215, 215);
}
.media li .mediaContent img {
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}
.media li .mediaContent p {
  font-size: 14px;
  padding: 8px 4px;
  text-align: center;
  color: rgb(100, 100, 100);
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 1px dotted rgb(215, 215, 215);
}

@media only screen and (max-width: 940px) {
  .filters {
    margin: 0 -10px;
  }
  .section ul {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 440px) {
  .input {
    padding-top: 40px;
  }
}

@keyframes animateColorLeft {
  from {
    background: transparent;
  }
  to {
    background-image: linear-gradient(90deg, #9278fa, transparent);
  }
}
@keyframes animateColorRight {
  from {
    background: transparent;
  }
  to {
    background-image: linear-gradient(-90deg, #9278fa, transparent);
  }
}
</style>
