<template>
  <div class="gallery">
    <div class="content">
      <div class="grid">
        <div class="image" v-for="img of this.files" :key="img._id">
          <img :src="`/files/${img.fileName}?token=${this.token}`" :alt="img.alt" />
          <div class="desc">
            <h4>{{ img.originalName }}</h4>
            <h5>{{ img.dateUploaded }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom" ref="bottom"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      number: 10,

      files: [],
    };
  },
  props: {
    token: String,
  },
  methods: {
    async fetchGallery(p = 1, n = 18) {
      try {
        const res = await fetch(`/files/gallery?n=${n}&p=${p}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.token}`,
            "content-type": "application/json",
          },
        });
        const data = await res.json();
        if (res.status === 200) {
          this.files = data.files;
        } else {
          this.$emit("displayMessage", [false, data.message]);
        }
      } catch (err) {
        console.log(`Error: ${err.name}`);
      }
    },
  },
  created() {
    this.fetchGallery();

    // setTimeout(() => {
    //   const options = {
    //     root: this.$refs.bottom,
    //     rootMargin: "0px 0px 0px 0px",
    //   };

    //   new IntersectionObserver((e) => {
    //     console.log(e);
    //     // this.fetchGallery(this.page++);
    //   }, options);
    // }, 1);
  },
};
</script>

<style scoped>
.content {
  width: 100%;
  min-height: 100vh;
  padding: 100px 40px 40px 40px;
}

.grid {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  gap: 40px;
}

.image {
  width: 100%;
}
.image img {
  width: 100%;
  height: auto;
  background-color: rgba(138, 138, 138, 0.15);
}
.image .desc {
  width: 100%;
}

.bottom {
  height: 100px;
  width: 100%;
}
</style>
