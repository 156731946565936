<template>
  <div class="account">
    <div class="login" v-if="!this.token">
      <p>Log in</p>
      <label>Email</label>
      <input v-model="email" @focus="this.error = ''" type="email" />
      <label>Password</label>
      <input v-model="password" @focus="this.error = ''" type="password" />
      <p class="error">{{ this.error }}</p>
      <button @click="login">Submit</button>
    </div>
    <div class="options" v-if="this.token">
      <h5>Welcome, {{ `${this.user?.email.split("@")[0].substring(2).split("_")[0].charAt(0).toUpperCase()}${this.user?.email.split("@")[0].substring(2).split("_")[0].slice(1)} ${this.user?.email.split("@")[0].substring(2).split("_")[1].toUpperCase()}.` }}</h5>
      <div class="pointsCont">
        <p class="count">{{ this.user?.credits }}</p>
        <p class="text">IBpedia Points</p>
      </div>
      <div class="links">
        <router-link class="link" to="/gallery">
          <img src="/images/gallery.png" alt="Gallery" />
          <h6>Gallery</h6>
        </router-link>
        <router-link class="link" to="/ranking">
          <img src="/images/ranking.png" alt="Ranking" />
          <h6>Ranking</h6>
        </router-link>
        <a class="link" href="https://sztorify.kanapka.eu" target="_blanc">
          <img src="https://sztorify.kanapka.eu/favicon.ico" alt="Sztorify" />
          <h6>Sztorify</h6>
        </a>
      </div>
      <div class="options">
        <router-link class="option" to="/">
          <img src="/images/home.png" alt="Home Page" />
          <h6>Home Page</h6>
        </router-link>
        <router-link class="option" to="/profile">
          <img src="/images/manageaccount.png" alt="Manage Account" />
          <h6>Manage Your Account</h6>
        </router-link>
        <router-link class="option" to="/settings">
          <img src="/images/settings.png" alt="Edit Settings" />
          <h6>Edit Settings</h6>
        </router-link>
      </div>
      <div class="actions">
        <button class="fullBtn" @click="this.$emit('logOut')">Log Out</button>
        <button class="emptyBtn">Need Help?</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",

      error: "",
      errorColor: "red",
    };
  },
  props: {
    token: String,
    user: Object,
  },
  methods: {
    async login() {
      try {
        const req = await fetch("/api/login", {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: this.email, password: this.password }),
        });

        if (req.status === 200) {
          const res = await req.json();
          this.errorColor = "green";
          this.error = res.message;
          this.$emit("updateToken", res.token);
        } else if (req.status === 429) {
          this.errorColor = "orange";
          this.error = "Please wait before trying again.";
        } else {
          const res = await req.json();
          this.errorColor = "red";
          this.error = res.message;
        }
      } catch (err) {
        console.log(`Error occured: ${err.name}`);
      }
    },
  },
};
</script>

<style scoped>
.account {
  padding: 20px;
  background-color: white;
  border-radius: 4px;
}
.account .options {
  min-width: 280px;
}
p {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}
h5 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
h6 {
  font-size: 17px;
  font-weight: 400;
  color: rgb(75, 75, 75);
  display: inline;
}
.login label {
  font-size: 14px;
  display: block;
}
.login input {
  width: 300px;
  display: block;

  padding: 6px 8px;
  margin-bottom: 8px;
  font-size: 16px;

  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(210, 210, 210);
  border-radius: 4px;
}
.login button {
  padding: 8px 46px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  background-color: #54e18c;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
}
.login button:hover {
  transform: translate(-4px, -4px);
  box-shadow: 4px 4px 0 black;
}
.error {
  margin: 10px 0;
  font-size: 14px;
  line-height: 16px;
  min-height: 16px;
  font-weight: 300;
  color: v-bind("errorColor");
}

.pointsCont {
  background-color: rgb(245, 245, 245);
  border: 1px dotted rgb(210, 210, 210);
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 16px;
}
.pointsCont .count {
  font-size: 36px;
  font-weight: 500;
  margin: 0 0 10px 0;
  color: rgb(52, 52, 52);
}
.pointsCont .text {
  font-size: 13px;
  font-weight: 300;
  margin: 0;
  color: rgb(100, 100, 100);
}

.option {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  text-decoration: none;

  width: calc(100% + 40px);
  /* width: 100%; */
  margin-left: -20px;
  padding: 8px 20px;
  /* padding: 6px 8px; */

  border-top: 1px dotted #dcdcdc;
}
.option:last-of-type {
  border-bottom: 1px dotted #dcdcdc;
}
.option img {
  height: 17px;
  aspect-ratio: 1/1;
  object-fit: contain;
}
.option h6 {
  font-size: 15px;
  color: #2c2c2c;
}
.option:hover {
  background-color: #f6f6f6;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
.links .link {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding: 8px 8px 4px 8px;
  border-radius: 4px;
  border: 1px solid transparent;
}
.link:hover {
  border: 1px dotted #dcdcdc;
}
.links a {
  text-decoration: none;
}
.links h6 {
  font-size: 13px;
  color: #5b5b5b;
}
.links img {
  filter: grayscale(1);
  height: 20px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.actions {
  padding-top: 20px;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.actions .emptyBtn,
.actions .fullBtn {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s;
}
.actions .fullBtn {
  padding: 6px;
  border: none;
  border-radius: 2px;
  background-color: #54e18c;
  color: #000000;
}
.actions .fullBtn:hover,
.actions .emptyBtn:hover {
  transform: scale(0.95);
}
.actions .emptyBtn {
  border: none;
  background-color: #ffffff;
  color: #000000;
  text-decoration: wavy underline;
  text-decoration-color: #54e18c;
}
</style>
