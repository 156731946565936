<template>
  <button class="uploadButton">
    <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.406 1.342C5.40548 0.480135 6.68024 0.00413852 8 0C10.69 0 12.923 2 13.166 4.579C14.758 4.804 16 6.137 16 7.773C16 9.569 14.502 11 12.687 11H10C9.86739 11 9.74021 10.9473 9.64645 10.8536C9.55268 10.7598 9.5 10.6326 9.5 10.5C9.5 10.3674 9.55268 10.2402 9.64645 10.1464C9.74021 10.0527 9.86739 10 10 10H12.688C13.979 10 15 8.988 15 7.773C15 6.557 13.98 5.545 12.687 5.545H12.187V5.045C12.188 2.825 10.328 1 8 1C6.91988 1.00431 5.87684 1.39443 5.059 2.1C4.302 2.752 3.906 3.538 3.906 4.155V4.603L3.461 4.652C2.064 4.805 1 5.952 1 7.318C1 8.785 2.23 10 3.781 10H6C6.13261 10 6.25979 10.0527 6.35355 10.1464C6.44732 10.2402 6.5 10.3674 6.5 10.5C6.5 10.6326 6.44732 10.7598 6.35355 10.8536C6.25979 10.9473 6.13261 11 6 11H3.781C1.708 11 0 9.366 0 7.318C0 5.555 1.266 4.095 2.942 3.725C3.085 2.862 3.64 2.002 4.406 1.342V1.342Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.64602 4.14601C7.69247 4.09945 7.74764 4.0625 7.80839 4.0373C7.86913 4.01209 7.93425 3.99911 8.00002 3.99911C8.06579 3.99911 8.13091 4.01209 8.19165 4.0373C8.2524 4.0625 8.30758 4.09945 8.35402 4.14601L11.354 7.14601C11.4479 7.2399 11.5007 7.36723 11.5007 7.50001C11.5007 7.63278 11.4479 7.76012 11.354 7.85401C11.2601 7.9479 11.1328 8.00064 11 8.00064C10.8672 8.00064 10.7399 7.9479 10.646 7.85401L8.50002 5.70701V14.5C8.50002 14.6326 8.44734 14.7598 8.35358 14.8536C8.25981 14.9473 8.13263 15 8.00002 15C7.86741 15 7.74024 14.9473 7.64647 14.8536C7.5527 14.7598 7.50002 14.6326 7.50002 14.5V5.70701L5.35402 7.85401C5.26013 7.9479 5.1328 8.00064 5.00002 8.00064C4.86725 8.00064 4.73991 7.9479 4.64602 7.85401C4.55213 7.76012 4.49939 7.63278 4.49939 7.50001C4.49939 7.36723 4.55213 7.2399 4.64602 7.14601L7.64602 4.14601Z" />
    </svg>
    Upload
  </button>
</template>

<style scoped>
.uploadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  font-weight: 400;
  padding: 4px 10px;
  background-color: rgb(239, 251, 254);
  border: 1px solid rgb(52, 150, 167);
  color: rgb(52, 150, 167);
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;
}
.uploadButton:hover {
  background-color: rgb(225, 250, 255);
}
.uploadButton svg {
  width: 16px;
  aspect-ratio: 1/1;
}
.uploadButton path {
  fill: rgb(52, 150, 167);
}
</style>
