<template>
  <div class="panel">
    <h1>ADMIN PANEL</h1>
    <div class="selectWindows">
      <button @click="currentWindow = 0" @click.once="getCourses">Add User</button>
      <button @click="currentWindow = 1" @click.once="getUsers">Manage Users</button>
      <button @click="currentWindow = 2" @click.once="getTopics">Manage Topics</button>
    </div>
    <div class="window" v-show="this.currentWindow === 0">
      <label>Email (username)</label>
      <input type="text" v-model="inviteMail" />
      <label>Courses allowed (#)</label>
      <input type="number" v-model="coursesAllowed" />
      <label>Course IDs (seperate with ,)</label>
      <input type="text" v-model="courses" />
      <button @click="invite">Generate Invite Link</button>
      <button @click="showCourseList = !showCourseList">Toggle Courses</button>
      <p class="link">{{ this.link }}</p>
      <p class="error">{{ this.error }}</p>
      <div class="courseList" v-show="showCourseList">
        <ul v-for="course of courseList" :key="course._id">
          <li>
            <p>{{ course.group }}</p>
            <p>{{ course.name }}</p>
            <p>{{ course._id }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="window" v-show="this.currentWindow === 1">
      <div class="user" v-for="user of this.userList" :key="user._id">
        <h5>Email: <input type="text" v-model="user.email" class="longInput" /> &nbsp;&nbsp;&nbsp; Role: <input type="text" v-model="user.permissions" class="shortInput" /> &nbsp;&nbsp;&nbsp; Id: {{ user._id }}</h5>
        <h6>Points: <input type="text" v-model="user.credits" class="numberInput" /> Courses Allowed: <input type="text" v-model="user.coursesAllowed" class="numberInput" /></h6>
        <div class="userCourse" v-for="(course, i) of user.courses" :key="course">
          <button @click="user.courses.splice(i, 1)">Kick</button>
          <p>Course {{ i }}: <input type="text" v-model="user.courses[i]" class="longInput" /></p>
        </div>
        <button @click="user.courses.push('')">Add Course</button>
        <button @click="saveUser(user)" class="saveButton">Save</button>
      </div>
    </div>
    <div class="window" v-show="this.currentWindow === 2">
      <div class="topics">
        <div class="topic" v-for="(topic, i) of this.topicList" :key="topic._id">
          <h5>{{ topic.title }}</h5>
          <h6>{{ new Date(topic.date).toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "long", day: "numeric" }) }}</h6>
          <div class="elements" v-show="topic.showContent">
            <div class="element" v-for="element of topic.elements" :key="element._id">
              <h4>Editor: {{ element.editorId }}</h4>
              <div class="contents">
                <div class="content" v-for="content of element.content" :key="content._id">
                  <h3 v-if="content.type === 'heading'">{{ content.text }}</h3>
                  <p v-if="content.type === 'paragraph'">{{ content.text }}</p>
                  <img v-if="content.type === 'image'" :src="`${content.src}?token=${token}`" />
                  <h6 v-if="content.type === 'image'">{{ content.alt }}</h6>
                  <ul v-if="content.type === 'list'">
                    <li v-for="listElement of content.list" :key="listElement">{{ listElement }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="elements" v-show="topic.contentHistoryVisible">
            <div class="element" v-for="(element, n) of topic.elements" :key="element._id">
              <div class="contents">
                <div class="content borderDotted" v-for="(contentHistory, h) of element.contentHistory" :key="contentHistory._id">
                  <h4>Date: {{ new Date(contentHistory.date).toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "long", day: "numeric" }) }}</h4>
                  <h4>Editor: {{ contentHistory.editorId }}</h4>
                  <h4>Difference: {{ contentHistory.difference }}</h4>
                  <button class="smallButton" @click="rewindContent(i, n, h)">Rewind Content</button>
                  <div class="content" v-for="contents of contentHistory" :key="contents._id">
                    <div class="content" v-for="content of contents" :key="content._id">
                      <h3 v-if="content.type === 'heading'">{{ content.text }}</h3>
                      <p v-if="content.type === 'paragraph'">{{ content.text }}</p>
                      <img v-if="content.type === 'image'" :src="`${content.src}?token=${token}`" />
                      <h6 v-if="content.type === 'image'">{{ content.alt }}</h6>
                      <ul v-if="content.type === 'list'">
                        <li v-for="listElement of content.list" :key="listElement">{{ listElement }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button @click="topic.showContent = !topic.showContent">Toggle content</button>
          <button @click="contentHistory(i)">Toggle Content History</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inviteMail: "",
      coursesAllowed: undefined,
      courses: "",

      currentWindow: 999,
      showCourseList: false,
      courseList: "",
      userList: "",
      topicList: "",

      link: "",
      error: "",
    };
  },
  props: {
    token: String,
  },
  methods: {
    async invite() {
      const req = await fetch("/api/register", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify({
          email: this.inviteMail + "@aswarsaw.org",
          coursesAllowed: this.coursesAllowed,
          courses: this.courses ? this.courses.replaceAll(" ", "").split(",") : undefined,
        }),
      });
      const res = await req.json();
      this.error = res.message;
      this.link = "";
      if (req.status === 200) this.link = res.activationLink;
    },
    async getCourses() {
      const res = await fetch("/api/courses", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      });
      if (res.status === 200) this.courseList = await res.json();
    },
    async getUsers() {
      const res = await fetch("/api/admin/users", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      });
      if (res.status === 200) this.userList = await res.json();
    },
    async getTopics() {
      const res = await fetch("/api/admin/topics", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      });
      if (res.status === 200) this.topicList = await res.json();
    },
    async saveUser(user, i) {
      const res = await fetch(`/api/admin/users/${user._id}`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify(user),
      });
      const data = await res.json();
      if (res.status === 200) {
        this.$emit("displayMessage", [true, data.message]);
        this.userList[i] = res.data.user;
      } else {
        this.$emit("displayMessage", [false, data.message]);
      }
    },
    async contentHistory(i) {
      if (this.topicList[i].contentHistoryVisible) {
        this.topicList[i].contentHistoryVisible = false;
        return;
      }
      const res = await fetch(`/api/admin/topics/${this.topicList[i]._id}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      });
      const data = await res.json();
      if (res.status === 200) {
        if (!this.topicList[i].contentHistoryVisible) this.topicList[i].contentHistoryVisible = true;
        this.topicList[i].contentHistory = data.elements;
      }
    },
    async rewindContent(topic, element, history) {
      const location = {
        _id: this.topicList[topic].elements[element].contentHistory[history]._id,
        editorId: this.topicList[topic].elements[element].contentHistory[history].editorId,
      };
      const res = await fetch(`/api/admin/topics/${this.topicList[topic]._id}/rewind`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
        body: JSON.stringify(location),
      });
      const data = await res.json();
      if (res.status === 200) {
        this.$emit("displayMessage", [true, data.message]);
        // this.topicList[topic] = res.data.topic;
      } else {
        this.$emit("displayMessage", [false, data.message]);
      }
    },
  },
};
</script>

<style scoped>
.user,
.topic {
  margin: 10px 0;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
}
.user h5,
.topic h5 {
  font-size: 20px;
  font-weight: 600;
  color: black;
}
.user h6,
.topic h6 {
  font-size: 16px;
  font-weight: 500;
  color: #3b3b3b;
}
.topic h6 {
  margin-bottom: 10px;
}
.elements {
  max-height: 80vh;
  overflow-y: scroll;
}
.element {
  border-top: 1px solid gray;
  margin: 0 0 10px 0;
  padding: 10px 0 0 0;
}
.element h4 {
  font-size: 12px;
  font-weight: 300;
  color: gray;
}
.element img {
  max-height: 100px;
  max-width: 100%;
  border-radius: 4px;
}
.element ul {
  margin-left: 20px;
  list-style: disc;
}
.element li {
  display: list-item;
}
.smallButton {
  text-decoration: underline;
  color: rgb(160, 160, 0);
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
}
.borderDotted {
  border-top: 1px dotted gray;
  margin: 5px 0;
  padding: 5px 0;
}
.user input {
  display: inline;
  font-size: 20px;
  padding: 0;
  border: none;
}
.user .saveButton {
  margin-top: 20px;
  padding: 6px 30px;
}
.user .longInput {
  width: 300px;
}
.user .shortInput {
  width: 90px;
}
.user .numberInput {
  width: 60px;
}
.userCourse {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.userCourse p {
  font-size: 16px;
}
.userCourse button {
  padding: 2px 8px;
  margin: 0;
  font-size: 14px;
}

.saveButton {
  display: block;
}

.selectWindows {
  margin: 0 0 40px 0;
}
.window {
  width: 100%;
}

.panel {
  max-width: 1000px;
  margin: 0 auto;
  padding: 75px 0;
}
.link {
  font-size: 13px;
  margin: 30px 0 15px 0;
}
.error {
  margin: 15px 0 30px 0;
}
label {
  margin-top: 6px;
  font-size: 16px;
}
button {
  margin: 8px 8px 0 0;
  padding: 4px 8px;
  font-size: 14px;
}
input {
  width: 100%;
  padding: 4px;
  font-size: 20px;
}
label,
input {
  display: block;
}
ul {
  list-style: none;
}
li {
  width: 100%;
  display: flex;
  margin: 2px 0;
}
li p {
  width: 33%;
}
</style>
