<template>
  <div class="activate" v-if="this.email && this.key">
    <div class="form">
      <h1>
        Activate your account, <span>{{ `${this.$route.query.email.split("@")[0].substring(2).split("_")[0].charAt(0).toUpperCase()}${this.$route.query.email.split("@")[0].substring(2).split("_")[0].slice(1)} ${this.$route.query.email.split("@")[0].substring(2).split("_")[1].toUpperCase()}.` }}</span>
      </h1>
      <label>Create Password</label>
      <input @keydown="resetError" v-model="password" type="password" />
      <label>Repeat Password</label>
      <input @keydown="resetError" v-model="repPassword" type="password" />
      <p>{{ error }}</p>
      <button @click="activateAccount">Submit</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: this.$route.query?.email,
      key: this.$route.query?.key,
      password: "",
      repPassword: "",
      error: "",
      errorColor: "red",
      borderColor: "#d2d2d2",
      backgroundColor: "#f5f5f5",
    };
  },
  methods: {
    async activateAccount() {
      if (!this.password || !this.repPassword) {
        this.errorColor = "red";
        this.error = "Fill in all fields";
        this.backgroundColor = "#fce6e6";
        this.borderColor = "#ff7e7e";
        return;
      }
      if (this.password !== this.repPassword) {
        this.errorColor = "red";
        this.error = "Passwords don't match!";
        this.backgroundColor = "#fce6e6";
        this.borderColor = "#ff7e7e";
        return;
      }

      this.error = "";
      this.backgroundColor = "#f5f5f5";
      this.borderColor = "#d2d2d2";

      try {
        const req = await fetch("/api/activate", {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email: this.email,
            key: this.key,
            password: this.password,
          }),
        });

        if (req.status === 200) {
          const res = await req.json();
          this.errorColor = "green";
          this.error = res.message;
          this.$emit("updateToken", res.token);
          setTimeout(() => this.$router.push("/"), 1500);
        } else if (req.status === 429) {
          this.errorColor = "orange";
          this.error = "Please wait before trying again.";
        } else {
          const res = await req.json();
          this.errorColor = "red";
          this.error = res.message;
        }
      } catch (err) {
        console.log(`Error occured: ${err.name}`);
      }
    },
    resetError() {
      this.error = "";
      this.borderColor = "#d2d2d2";
      this.backgroundColor = "#f5f5f5";
    },
  },
};
</script>

<style scoped>
.activate {
  min-height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 360px;
}
.form h1 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 600;
}
.form h1 span {
  font-weight: 700;
}
.form p {
  margin: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  min-height: 16px;
  font-weight: 300;
  color: v-bind("errorColor");
}
.form label {
  margin-top: 4px;
  font-size: 14px;
  display: block;
}
.form input {
  width: 300px;
  display: block;

  padding: 6px 8px;
  margin-bottom: 8px;
  font-size: 16px;

  background-color: v-bind("backgroundColor");
  border: 1px solid v-bind("borderColor");
  border-radius: 4px;
}
button {
  margin: auto;
  margin-top: 10px;
  padding: 0.5rem 2rem;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  transform: translate(-4px, -4px);
  box-shadow: 4px 4px 0 black;
  background-color: rgb(97, 227, 149);
  cursor: pointer;
  transition: 0.1s;
  width: fit-content;
}
button:hover {
  transform: translate(0, 0);
  box-shadow: 0 0 0 black;
}
</style>
