<template>
  <div class="container">
    <div v-if="this.token" class="user">
      <div class="top">
        <div class="forbidden" v-if="forbiddenMessage">
          <img src="/images/errorImage.png" alt="Error Image" />
          <h1>Couldn't load "{{ this.$route.href.split("/").at(-1).replaceAll("%20", " ") }}"</h1>
          <p>Reason: {{ forbiddenMessage }}</p>
        </div>
      </div>
      <div v-show="!topicAvalible && !courseAvalible && mode !== 3 && !forbidden" class="progressBar">
        <div class="bar"></div>
      </div>
      <transition name="topic">
        <TopicComponent v-if="mode === 2" v-show="this.topicAvalible" :token="this.token" @changeColor="changeColor" @loaded="this.topicAvalible = true" @displayMessage="emitMessage" @forbidden="forbiddenRoute" @updateUser="this.$emit('updateUser')" />
      </transition>
      <transition name="course">
        <CourseComponent class="positioned" v-if="mode === 1" v-show="this.courseAvalible" :token="this.token" :user="this.user" @changeColor="changeColor" @loaded="this.courseAvalible = true" @displayMessage="emitMessage" @forbidden="forbiddenRoute" @updateUser="this.$emit('updateUser')" />
      </transition>
      <transition name="search">
        <SearchComponent class="positioned" v-if="mode === 3" :token="this.token" @changeColor="changeColor" @displayMessage="emitMessage" :colors="this.colors" />
      </transition>
    </div>
    <div v-if="!this.token" class="guest">
      <img src="/images/errorImage.png" alt="Error Image" />
      <h1>Log in for further access</h1>
      <p>If you don't have an account, ask for one</p>
    </div>
  </div>
</template>

<script>
import TopicComponent from "../components/TopicComp.vue";
import CourseComponent from "../components/CourseComp.vue";
import SearchComponent from "../components/SearchComp.vue";

export default {
  data() {
    return {
      mode: 0,
      modeCode: {
        topics: 2,
        courses: 1,
        search: 3,
      },
      forbidden: false,
      forbiddenMessage: "",
      topicAvalible: false,
      courseAvalible: false,

      colors: {
        math: ["#feeded", "#ff6b6b"],
        humanities: ["#ecfcff", "#48c3de"],
        languages: ["#fff7eb", "#e3ad5b"],
        sciences: ["#e4ffed", "#3dd871"],
        search: ["#ede8ff", "#9278fa"],
        error: ["#ff0000", "#ffffff"],
      },
      color3: "#ffffff",
      color11: "#ffffff",

      topHeightNormal: "300px",
      topHeightMobile: "340px",
    };
  },
  props: {
    token: String,
    user: Object,
  },
  components: {
    TopicComponent,
    CourseComponent,
    SearchComponent,
  },
  methods: {
    changeColor(data) {
      this.color3 = this.colors[data][1];
      this.color11 = this.colors[data][0];
    },
    emitMessage(data) {
      this.$emit("displayMessage", data);
    },
    forbiddenRoute(data) {
      this.forbidden = true;
      this.forbiddenMessage = data;
      this.mode = 999;
      this.changeColor("error");
      this.setTopHeight();
      this.changeColor;
    },
    setTopHeight() {
      if (this.mode === 3) {
        this.topHeightNormal = "220px";
        this.topHeightMobile = "220px";
      } else if (this.mode === 999) {
        this.topHeightNormal = "0";
        this.topHeightMobile = "0";
      } else {
        this.topHeightNormal = "300px";
        this.topHeightMobile = "340px";
      }
    },
  },
  created() {
    this.mode = this.modeCode[this.$route.path.split("/")[1]];
    this.setTopHeight();
  },
  watch: {
    "$route.href": function () {
      this.mode = this.modeCode[this.$route.path.split("/")[1]];
      this.topicAvalible = false;
      this.courseAvalible = false;
      this.forbidden = false;
      this.forbiddenMessage = "";
      this.setTopHeight();
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.container {
  min-height: 100vh;
}
.top {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: v-bind("topHeightNormal");
  z-index: -1;

  background-color: v-bind("color3");
  transition: height 0.4s cubic-bezier(0.16, 1, 0.3, 1), background-color 0.15s ease-out;
}
.positioned {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.progressBar {
  position: absolute;
  top: v-bind("topHeightNormal");
  left: 0;

  height: 5px;
  width: 100%;
}
.progressBar .bar {
  width: 0%;
  height: 100%;
  background-color: rgb(118, 118, 118);
  animation: load 2s forwards;
  animation-delay: 0.5s;
}

.forbidden {
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.forbidden h1,
.forbidden p {
  max-width: 60%;
}
.forbidden img {
  width: 100px;
  aspect-ratio: 1/1;
  transform: scale(2.5);
  overflow: hidden;
}

@keyframes load {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}

.topic-enter-active,
.course-enter-active {
  transition: 0.5s cubic-bezier(0.16, 1, 0.3, 1);
}
.topic-leave-active,
.course-leave-active {
  transition: 0.3s cubic-bezier(0.16, 1, 0.3, 1);
}
.course-enter-from,
.course-leave-to,
.topic-leave-to,
.search-leave-to {
  opacity: 0;
}
.topic-enter-from {
  transform: translateY(70px);
}
.search-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}

@media only screen and (max-width: 700px) {
  .top {
    height: v-bind("topHeightMobile");
  }
  .progressBar {
    top: v-bind("topHeightMobile");
  }
}
</style>
