<template>
  <div class="enrollCourseOverlay">
    <div class="enrollCourse">
      <svg class="close" @click="this.$emit('close')" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.146021 0.853991C0.0995332 0.807503 0.0626571 0.752314 0.037498 0.691574C0.0123389 0.630835 -0.000610351 0.565735 -0.000610352 0.499991C-0.000610352 0.434247 0.0123389 0.369147 0.037498 0.308407C0.0626571 0.247668 0.0995332 0.192479 0.146021 0.145991C0.192509 0.0995027 0.247698 0.0626266 0.308438 0.0374675C0.369177 0.0123084 0.434277 -0.00064087 0.500021 -0.000640869C0.565765 -0.000640869 0.630865 0.0123084 0.691605 0.0374675C0.752344 0.0626266 0.807533 0.0995027 0.854021 0.145991L6.00002 5.29299L11.146 0.145991C11.1925 0.0995027 11.2477 0.0626266 11.3084 0.0374675C11.3692 0.0123084 11.4343 -0.000640869 11.5 -0.000640869C11.5658 -0.000640869 11.6309 0.0123084 11.6916 0.0374675C11.7523 0.0626266 11.8075 0.0995027 11.854 0.145991C11.9005 0.192479 11.9374 0.247668 11.9625 0.308407C11.9877 0.369147 12.0007 0.434247 12.0007 0.499991C12.0007 0.565735 11.9877 0.630835 11.9625 0.691574C11.9374 0.752314 11.9005 0.807503 11.854 0.853991L6.70702 5.99999L11.854 11.146C11.9005 11.1925 11.9374 11.2477 11.9625 11.3084C11.9877 11.3691 12.0007 11.4342 12.0007 11.5C12.0007 11.5657 11.9877 11.6308 11.9625 11.6916C11.9374 11.7523 11.9005 11.8075 11.854 11.854C11.8075 11.9005 11.7523 11.9374 11.6916 11.9625C11.6309 11.9877 11.5658 12.0006 11.5 12.0006C11.4343 12.0006 11.3692 11.9877 11.3084 11.9625C11.2477 11.9374 11.1925 11.9005 11.146 11.854L6.00002 6.70699L0.854021 11.854C0.807533 11.9005 0.752344 11.9374 0.691605 11.9625C0.630865 11.9877 0.565765 12.0006 0.500021 12.0006C0.434277 12.0006 0.369177 11.9877 0.308438 11.9625C0.247698 11.9374 0.192509 11.9005 0.146021 11.854C0.0995332 11.8075 0.0626571 11.7523 0.037498 11.6916C0.0123389 11.6308 -0.000610352 11.5657 -0.000610352 11.5C-0.000610352 11.4342 0.0123389 11.3691 0.037498 11.3084C0.0626571 11.2477 0.0995332 11.1925 0.146021 11.146L5.29302 5.99999L0.146021 0.853991Z"
          fill="black"
        />
      </svg>
      <h1>Enroll in {{ course }}</h1>
      <p class="course" v-if="coursesAllowed > coursesNum">Are you sure you want to enroll in this course? You can enroll in {{ coursesAllowed - coursesNum }} more {{ coursesAllowed - courseNum > 1 ? "courses" : "course" }}.</p>
      <p class="course" v-if="coursesAllowed <= coursesNum">You can't enroll in any more courses.</p>
      <p class="error">{{ this.error }}</p>
      <button class="fullBtn" v-if="coursesAllowed > coursesNum" @click="enroll">Yes</button>
      <button class="fullBtn" v-if="coursesAllowed <= coursesNum" @click="this.$emit('close')">Ok</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    course: String,
    token: String,
    coursesAllowed: Number,
    coursesNum: Number,
  },
  data() {
    return {
      error: "",
      errorColor: "red",
    };
  },
  methods: {
    changeSrc(source) {
      this.thumbnail = source;
    },
    async enroll() {
      const req = await fetch(`/api/courses/${this.course}/enroll`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      const res = await req.json();

      if (req.status === 200) {
        this.errorColor = "green";
        this.$emit("enrolled");
      } else {
        this.errorColor = "red";
      }
      this.error = res?.message || "Server did not respond";
    },
  },
};
</script>

<style scoped>
.enrollCourseOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.enrollCourse {
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  box-shadow: 0 0 5rem 1rem rgba(0, 0, 0, 0.2);
}
.course {
  font-size: 14px;
  font-weight: 400;
}
.enrollCourse h1 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 15px;
}

.error {
  margin: 10px 0;
  font-size: 14px;
  line-height: 16px;
  min-height: 16px;
  font-weight: 300;
  color: v-bind("errorColor");
}
.fullBtn {
  margin: auto;
  margin-top: 10px;
  padding: 0.5rem 2rem;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  transform: translate(-4px, -4px);
  box-shadow: 4px 4px 0 black;
  background-color: rgb(97, 227, 149);
  color: black;
  cursor: pointer;
  transition: 0.1s;
  width: fit-content;
}
.fullBtn:hover {
  transform: translate(0, 0);
  box-shadow: 0 0 0 black;
}

@media only screen and (max-width: 440px) {
  .enrollCourse {
    padding: 15px;
    width: calc(100vw - 20px);
    min-width: unset;
  }
}

.v-enter-active,
.v-leave-active {
  transition: 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: scale(1.1);
}
</style>
