<template>
  <div class="bar" :style="`background-color: ${this.ok ? 'rgb(45, 135, 50)' : 'rgb(233, 76, 76)'};`">
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: {
    ok: Boolean,
    message: String,
  },
};
</script>

<style scoped>
.bar {
  width: 100%;
  padding: 8px 24px;
}
.bar p {
  font-size: 17px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  text-align: center;
}
</style>
