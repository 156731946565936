<template>
  <div class="course">
    <transition>
      <CreateTopic v-if="creatingTopic" :course="course.name" :token="token" @close="creatingTopic = false" @topicCreated="fetchCourse" />
    </transition>
    <transition>
      <EnrollInCourse v-if="joiningCourse" :course="course.name" :coursesAllowed="user.coursesAllowed" :coursesNum="user.courses.length" :token="token" @close="joiningCourse = false" @enrolled="enrolledUser" />
    </transition>
    <div class="topEmpty">
      <div class="courseDesc">
        <img class="dots" src="/images/dots.png" alt="Fancy Dots Decoration" />
        <div class="title">
          <h1>{{ course.name || "&nbsp;" }}</h1>
          <h2>{{ course.group || "&nbsp;" }}</h2>
        </div>
        <ul>
          <li>
            Enrolled: <span>{{ course.enrolled || 0 }}</span>
          </li>
          <li>
            Topics: <span>{{ course.topicsIds?.length }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="course.resources?.length" class="section resources">
      <div class="resourceCont">
        <div class="resourceScroll">
          <a class="resource" :href="resource.link" target="_blank" v-for="resource of this.course.resources" :key="resource._id">
            <img :src="resource.icon" :alt="`${resource.name} icon`" @error="replaceSource" />
            <p>{{ resource.name }}</p>
          </a>
        </div>
      </div>
    </div>
    <div class="section topics">
      <div class="sectionHead topicsHead">
        <h3 class="sectionTitle topicsTitle">Topics</h3>
        <button v-if="this.user.courses?.includes(this.course._id)" @click="this.creatingTopic = true">Create Topic</button>
        <button v-if="!this.user.courses?.includes(this.course._id)" @click="this.joiningCourse = true">Enroll</button>
      </div>
      <div class="topicGrid">
        <div class="topic coolLoad" v-for="(topic, i) of course.topics" :style="`animation-delay: ${i * 0.075}s`" :key="i" @click="this.$router.push(`/topics/${topic.title}`)">
          <img :src="topic.thumbnail.startsWith('/files') ? `${topic.thumbnail}?token=${this?.token}` : topic.thumbnail" :alt="`${topic.title} thumbnail`" @error="replaceSource" />
          <div class="info">
            <h5>{{ topic.title }}</h5>
            <p>{{ new Date(topic.date).toLocaleDateString("en", { day: "numeric", month: "long", year: "numeric" }) }}</p>
            <div class="options" v-if="topic.options">
              <button>Remove</button>
            </div>
          </div>
        </div>
      </div>
      <div class="noTopics" v-if="course?.topics && !course.topics.length">
        <h3>No topics yet</h3>
      </div>
      <div v-if="this.user.courses?.includes(this.course._id)" class="sectionHead forumHead">
        <h3 class="sectionTitle forumTitle">Forum</h3>
        <button v-if="this.user.courses?.includes(this.course._id)">Ask something</button>
      </div>
    </div>
  </div>
</template>

<script>
import CreateTopic from "../components/CreateTopicComp.vue";
import EnrollInCourse from "../components/EnrollInCourse.vue";

export default {
  data() {
    return {
      creatingTopic: false,
      joiningCourse: false,
      color3: undefined,
      color11: undefined,
      colors: {
        math: ["#feeded", "#ff6b6b"],
        humanities: ["#ecfcff", "#48c3de"],
        languages: ["#fff7eb", "#e3ad5b"],
        sciences: ["#e4ffed", "#3dd871"],
      },
      course: {},
    };
  },
  components: {
    CreateTopic,
    EnrollInCourse,
  },
  props: {
    token: String,
    user: Object,
  },
  methods: {
    async fetchCourse() {
      if (this.$route.params.string === undefined) return;
      if (this.$route.path.split("/")[1] !== "courses") return;

      this.course = {};

      try {
        const req = await fetch(`/api/courses/${this.$route.params.string}`, {
          method: "GET",
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        const res = await req.json();

        if (req.status !== 200) {
          return this.$emit("displayMessage", [false, res.message]);
        } else {
          this.$emit("loaded");

          this.course = res.course;
          this.color3 = this.colors[this.course.group][1];
          this.color11 = this.colors[this.course.group][0];
          this.$emit("changeColor", this.course.group);
        }
      } catch (err) {
        console.log(`Error occured: ${err.name}`);
      }
    },
    enrolledUser() {
      this.joinCourse = false;
      this.$emit("updateUser");
    },
    replaceSource(e) {
      e.target.src = "/images/notFoundImage.png";
    },
  },
  watch: {
    "$route.params.string": function () {
      this.fetchCourse();
    },
  },
  created() {
    this.fetchCourse();
  },
};
</script>

<style scoped>
.topEmpty {
  width: 100%;
  padding-top: 60px;
  height: 300px;
  overflow: hidden;
}
.courseDesc {
  position: relative;

  margin: 0 auto;
  max-width: 1000px;
  height: 100%;
}
.courseDesc .dots {
  position: absolute;
  top: 45px;
  left: 10px;
}
.courseDesc .title {
  background-color: white;
  min-width: 50%;
  width: fit-content;
  max-width: 60%;
  padding: 12px 20px;

  position: absolute;
  top: 65px;
  left: 50px;
  z-index: 0;
}
.courseDesc .title::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 60px;
  width: 100%;
  height: 100%;
  z-index: -1;
  border: 1px solid white;
}
.courseDesc .title h1 {
  font-size: 37px;
  font-weight: 700;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.courseDesc .title h2 {
  font-size: 17px;
  font-weight: 300;
  color: rgb(78, 78, 78);
}
.courseDesc ul {
  background: rgb(255, 255, 255);
  min-width: 140px;
  width: fit-content;
  list-style: none;

  position: absolute;
  bottom: 30px;
  right: 30px;

  padding: 10px 20px;
}
.courseDesc ul::before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;

  right: -24px;
  width: 5px;
  border-left: 9px solid white;
  border-right: 5px solid white;
}
.courseDesc ul::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;

  right: -35px;
  width: 4px;
  border-left: 2px solid white;
}
.courseDesc li {
  font-size: 15px;
  font-weight: 300;
}
.courseDesc li span {
  font-weight: 600;
}

.sectionHead {
  padding-bottom: 5px;

  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.sectionTitle {
  font-size: 22px;
  font-weight: 500;
}
.sectionHead button {
  color: v-bind("color3");
  border: 1px solid v-bind("color3");
  padding: 5px 10px;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.section {
  margin: 0 auto;
  max-width: 900px;
}
.topics {
  min-height: calc(100vh - 400px);
  padding-bottom: 100px;
}

.resourceCont {
  position: relative;
  margin-top: 14px;
  margin-bottom: -10px;

  margin-left: -10px;
  width: calc(100% + 20px);
}
.resourceCont::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
  background-image: linear-gradient(90deg, rgb(245, 245, 245), transparent);
}
.resourceCont::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  background-image: linear-gradient(-90deg, rgb(245, 245, 245), transparent);
}
.resourceScroll {
  width: min-content;
  display: flex;
  gap: 15px;

  padding: 10px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
.resource {
  text-align: center;
  background-color: white;
  padding: 8px;
  border-radius: 40px;
  text-decoration: none;
  color: black;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: 5px 0 10px rgba(138, 138, 138, 0.1);
  transition: 0.2s;
}
.resource:hover {
  box-shadow: 0 0 10px rgba(138, 138, 138, 0.1);
  background-color: rgb(245, 245, 245);
}
.resource img {
  height: 24px;
  margin-left: 5px;
}
.resource p {
  white-space: nowrap;
  font-size: 16px;
  margin-right: 10px;
}

.topicsHead {
  margin: 20px 0;
}
.topicGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}
.topic {
  position: relative;
  width: 100%;
  height: fit-content;
  cursor: pointer;
  box-shadow: 0 10px 20px 15px rgba(138, 138, 138, 0.1);
}
.topic img {
  min-height: 200px;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  transition: 0.3s;
  background-color: rgba(138, 138, 138, 0.15);
  border-radius: 4px;
}
.topic:hover .info {
  padding-bottom: 34px;
}

.topic .info {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  min-height: 40px;

  padding: 10px 10px 10px 14px;
  background-color: white;
  border-top: 1px dotted rgb(200, 200, 200);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: 0.2s;
}
.topic h5 {
  font-size: 22px;
  font-weight: 600;
  max-height: 150px;
  overflow: hidden;
}
.topic p {
  font-size: 12px;
  font-weight: 300;
  color: rgb(60, 60, 60);
  margin-bottom: 4px;
}

.noTopics {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 80px 0;
  width: 100%;
}
.noTopics h3 {
  font-size: 20px;
  font-weight: 300;
  color: rgb(69, 69, 69);
}

.joinCourse {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);
  z-index: 999;
}

.forumHead {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px dotted #cacaca;
}

@media only screen and (max-width: 940px) {
  .section {
    width: calc(100vw - 40px);
  }
  .topicsTitle {
    font-size: 20px;
  }
  .topicsHead button {
    color: v-bind("color3");
    border: 1px solid v-bind("color3");
  }
  .topic {
    grid-template-columns: 40px auto 30px;
    gap: 15px;
  }
  .topic h5 {
    font-size: 21px;
  }
  .topic p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 700px) {
  .topEmpty {
    height: 340px;
  }
  .topicGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .courseDesc .dots {
    top: 40px;
  }
  .courseDesc .title {
    top: 55px;
    left: 20px;
    min-width: 80%;
    max-width: calc(100% - 40px);
  }
  .courseDesc .title::after {
    top: 20px;
    left: 40px;
  }
  .courseDesc .title h1 {
    font-size: 28px;
  }
  .courseDesc .title h2 {
    font-size: 14px;
  }
  .courseDesc ul {
    background: rgb(255, 255, 255);
    min-width: 120px;
    width: fit-content;
    list-style: none;

    position: absolute;
    bottom: 30px;
    right: 30px;

    padding: 7px 14px;
  }
  .courseDesc li {
    font-size: 11px;
  }
}

@media only screen and (max-width: 440px) {
  .topicGrid {
    grid-template-columns: repeat(1, 1fr);
  }

  .progressBar {
    padding-top: 55px;
    height: 60px;
  }
  .resourceCont {
    margin-top: 9px;
  }
  .resource img {
    height: 18px;
    margin-left: 4px;
  }
  .resource p {
    font-size: 13px;
    margin-right: 8px;
  }

  .section {
    width: calc(100vw - 30px);
  }
  .topicsTitle {
    font-size: 17px;
  }
  .topicsHead button {
    color: v-bind("color3");
    border: 1px solid v-bind("color3");
    padding: 4px 8px;
    font-size: 12px;
  }
  .topic {
    grid-template-columns: 40px auto;
    gap: 15px;
  }
  .topic h5 {
    font-size: 26px;
  }
  .topic p {
    font-size: 14px;
  }

  /* NEW */

  .topEmpty {
    padding-top: 40px;
  }
}

.v-enter-active,
.v-leave-active {
  transition: 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: scale(1.1);
}

.coolLoad {
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
  animation-timing-function: ease-out;
}
@keyframes fadeIn {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
