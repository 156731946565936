import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ContentView from "../views/ContentView.vue";
import ActivateView from "../views/ActivateView.vue";
import GalleryView from "../views/GalleryView.vue";
import AdminView from "../views/AdminView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/topics/:string",
    name: "topicView",
    component: ContentView,
  },
  {
    path: "/courses/:string",
    name: "courseView",
    component: ContentView,
  },
  {
    path: "/search",
    name: "searchView",
    component: ContentView,
  },
  {
    path: "/activate",
    name: "activateView",
    component: ActivateView,
  },
  {
    path: "/gallery",
    name: "galleryView",
    component: GalleryView,
  },
  {
    path: "/admin",
    name: "adminView",
    component: AdminView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
