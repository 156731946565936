<template>
  <div class="home">
    <div class="hero">
      <img src="https://assets.kanapka.eu/images/classroom.jpeg" alt="THE Classroom" />
      <div class="text">
        <h1>Cooperate Today<br /><span>Ace</span> Tomorrow</h1>
        <p>Share your notes, thoughts and questions on our website.</p>
        <button>Request account</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.hero {
  position: relative;
  min-height: 100vh;

  padding: 60px 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 120px;
  align-items: center;
}
.hero img {
  justify-self: right;
  max-width: 100%;
  max-height: 360px;
}
.hero .text {
  justify-self: left;
}
.hero .text h1 {
  font-size: 70px;
  letter-spacing: -2px;
  line-height: 92.5px;
}
.hero .text p {
  font-size: 18px;
  font-weight: 300;
  color: rgb(91, 91, 91);

  margin-top: 40px;
  margin-bottom: 20px;
}
.hero .text span {
  padding: 0 0.5rem;
  background-color: rgb(169, 225, 169);
}
.hero .text button {
  padding: 0.75rem 2.5rem;
  font-size: 22px;
  font-weight: 600;
  border: none;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgb(97, 227, 149);
  transform: translate(-4px, -4px);
  margin-left: 4px;
  box-shadow: 4px 4px 0 black;
  cursor: pointer;
  transition: 0.1s;
}
.hero .text button:hover {
  transform: translate(0, 0);
  box-shadow: 0 0 0 black;
}

@media only screen and (max-width: 1540px) {
  .hero {
    padding: 0;
    width: calc(100vw - 100px);
    gap: 80px;
    margin: 0 auto;
  }
  .hero .text h1 {
    font-size: 48px;
    letter-spacing: unset;
    line-height: unset;
  }
}
@media only screen and (max-width: 1240px) {
  .hero .text h1 {
    font-size: 40px;
    letter-spacing: unset;
    line-height: unset;
  }
}
@media only screen and (max-width: 940px) {
  .hero {
    grid-template-columns: 1fr;
    padding: 100px 0;
    gap: 80px;
    align-items: center;
  }
  .hero img {
    justify-self: center;
    max-height: calc(100vh / 3);
    max-height: unset;
  }
  .hero .text {
    justify-self: center;
    text-align: center;
  }
}
@media only screen and (max-width: 440px) {
  .hero {
    padding: 55px 0;
    width: 100%;
    gap: 40px;
    align-items: center;
  }
  .hero img {
    justify-self: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .hero .text {
    padding: 0 20px;
    align-self: flex-start;
    justify-self: center;
    text-align: center;
  }
  .hero .text h1 {
    font-size: 34px;
  }
  .hero .text p {
    font-size: 15px;
  }
  .hero .text button {
    padding: 0.6rem 2rem;
    font-size: 16px;
  }
}
</style>
