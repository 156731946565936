<template>
  <div class="app">
    <NavigationComp @displayMessage="displayMessage" @updateToken="updateToken" @logOut="logOut" :user="this.user" :token="this.token" :width="width" class="navigation" />
    <router-view @displayMessage="displayMessage" @updateToken="updateToken" :token="this.token" :user="this.user" :width="width" @updateUser="this.syncUser" />
    <transition>
      <ErrorBarComp :ok="this.ok" :message="this.message" v-show="this.displayError" class="errorBar" />
    </transition>
  </div>
</template>

<script>
import NavigationComp from "./components/NavigationComp.vue";
import ErrorBarComp from "./components/ErrorBarComp.vue";

export default {
  data() {
    return {
      token: "",
      user: undefined,
      width: 3840,
      displayError: false,
      errorTimer: setTimeout(() => {}, 0),
      ok: false,
      message: "",

      websocket: undefined,
    };
  },
  components: {
    NavigationComp,
    ErrorBarComp,
  },
  methods: {
    updateToken(token) {
      this.token = token;
      window.localStorage.setItem("token", this.token);
      this.syncUser();
    },
    updateUser(user) {
      this.user = user;
      window.localStorage.setItem("user", JSON.stringify(user));
    },
    async syncUser() {
      try {
        const req = await fetch("/api/me", {
          method: "GET",
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        if (req.status === 200) {
          const res = await req.json();
          this.updateUser(res.user);
        } else if (req.status === 502) {
          this.displayMessage("Server offline. CMD + SHIFT + R to learn more.");
        }
      } catch (err) {
        console.log(`Error occured: ${err.name}`);
      }
    },
    logOut() {
      window.localStorage.clear();
      this.token = "";
      this.user = undefined;
    },
    displayMessage(data) {
      this.ok = data[0];
      this.message = data[1];
      this.displayError = true;
      // WORKS?
      clearTimeout(this.errorTimer);
      this.errorTimer = setTimeout(() => (this.displayError = false), 2500);
    },
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("user"));
    this.token = window.localStorage.getItem("token");
    if (this.token) this.syncUser();

    this.width = window.innerWidth;
    window.addEventListener("resize", () => (this.width = window.innerWidth));

    if (!this.token) return;

    this.websocket = new WebSocket("ws://localhost:4000");
    this.websocket.addEventListener("open", () => {
      this.websocket.send(
        JSON.stringify({
          type: "auth",
          token: this.token,
        })
      );
    });
  },
  unmounted() {
    window.removeEventListener("resize", () => (this.width = window.innerWidth));
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --math-color-11: #feeded;
  --math-color-9: #ffc8c8;
  --math-color-6: #ff9797;
  --math-color-3: #ff6b6b;

  --humanities-color-11: #ecfcff;
  --humanities-color-9: #c8f5ff;
  --humanities-color-6: #7ed5e8;
  --humanities-color-3: #48c3de;

  --languages-color-11: #fff7eb;
  --languages-color-9: #ffe1b4;
  --languages-color-6: #f6be6a;
  --languages-color-3: #e3ad5b;

  --sciences-color-11: #e4ffed;
  --sciences-color-9: #b4ffcd;
  --sciences-color-6: #57f08a;
  --sciences-color-3: #3dd871;

  --search-color-11: #ede8ff;
  --search-color-9: #ccbfff;
  --search-color-6: #957bfa;
  --search-color-3: #9278fa;
}

::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #cecece;
}

::-webkit-scrollbar-thumb:hover {
  background: #616161;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

body {
  /* overscroll-behavior-y: none; */
  background-color: rgb(243, 243, 243);
}

.navigation {
  position: fixed;
  width: 100%;
  z-index: 949;
  box-shadow: 0px 8px 30px 15px rgba(0, 0, 0, 0.05);

  /* FIX */
  top: 0;
  left: 0;
}
.errorBar {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 949;
}

.guest {
  height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.guest h1,
.guest p {
  max-width: 60%;
  text-align: center;
}
.guest img {
  width: 100px;
  aspect-ratio: 1/1;
  transform: scale(2.5);
  overflow: hidden;
}

@media only screen and (max-width: 440px) {
  * {
    overscroll-behavior-y: auto;
  }

  /* NEW */

  .navigation {
    bottom: 0;
    top: unset;
  }
  .errorBar {
    bottom: unset;
    top: 0;
  }
  .v-enter-from,
  .v-leave-to {
    transform: translateY(-100%) !important;
  }
}

.loading {
  animation: 1s infinite alternate loading;
}
@keyframes loading {
  from {
    background-color: rgba(224, 224, 224, 0.8);
  }
  to {
    background-color: rgba(211, 211, 211, 0.2);
  }
}

.v-enter-active,
.v-leave-active {
  transition: 0.4s cubic-bezier(0.16, 1, 0.3, 1);
}

.v-enter-from,
.v-leave-to {
  transform: translateY(100%);
}
</style>
